import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import axios from 'axios';
import './Works.scss';


function getCheckedProjects(projects) {

    const cofcofProjects = projects.cofcof;

    let allCofCofProjects = [];
    let checkedCofCofProjects = [];

    for (let property in cofcofProjects) {
        allCofCofProjects.push((cofcofProjects[property]));
    }

    // --------------------------------------------------------

    allCofCofProjects.map(cofcofProject => {
        if (cofcofProject[Object.keys(cofcofProject)[0]].checked === true) {
            checkedCofCofProjects.push(cofcofProject);
        }
    })

    return checkedCofCofProjects;
}

function getProjectsCovers(covers) {

    const projectsCovers = [];

    for (let property in covers) {
        projectsCovers.push((covers[property]));
    }

    return projectsCovers;
}

function getFirstProjectCover(covers, projects) {
    let firstCover;

    let phantom = projects.find(project => project !== undefined);

    if (phantom !== undefined) {
        firstCover = covers.find(cover => cover.nombre_proyecto === Object.values(phantom)[0].nombre_proyecto)
    }


    return firstCover.link_media;
}

function getFirstProjectName(projects) {
    // let firstProjectName = Object.values(projects[0])[0].nombre_proyecto;

    let firstProjectName;

    let phantom = projects.find(project => project !== undefined);

    if (phantom !== undefined) {
        firstProjectName = Object.values(phantom)[0].nombre_proyecto;
    }

    return firstProjectName;
}

function getProjectData(projectName, projectsData) {
    const projects = [];

    for (let property in projectsData) {
        projects.push((projectsData[property]))
    }

    let data = projects.find(project => Object.values(project)[0].nombre_proyecto === projectName);

    return data;
}

function getProjectEmbed(projectData) {

    let embed = Object.values(projectData)[0].embebido;

    return embed;
}

function getProjectMedia(projectName, projectMedia) {
    let media;

    for (let property in projectMedia) {
        if (property === projectName) {
            media = (Object.values(projectMedia[property]));
        }
    }

    return media;
}

function getProjectLinks(projectData) {

    let links = Object.values(projectData)[0].links;

    let linksArray = links.split(',');

    return linksArray;
}

function getProjectLinksName(link) {

    return link.replace(/(^.*\[|\].*$)/g, '');
}

function getProjectLinksDirection(link) {

    return link.replace(/\s*\[.*?\]\s*/g, '');
}

function getDataVerification(projectData) {
    let data = Object.values(projectData)[0].cofcof

    return data;
}

function sortProjects(projectsOrder, projects) {
    let cofcofProjectsOrder = projectsOrder.cofcof;
    let sortedProjects = [];


    cofcofProjectsOrder.map(order => {
        let xd = projects.find(project => Object.values(project)[0].nombre_proyecto === order.name);

        sortedProjects.push(xd);
    })

    return sortedProjects;
}


export default function Works() {

    const [checkedProjects, setCheckedProjects] = useState([]);
    const [projectsCovers, setProjectsCovers] = useState([]);
    const [projectName, setProjectName] = useState('');
    const [projectData, setProjectData] = useState();
    const [projectEmbed, setProjectEmbed] = useState('');
    const [dataVerification, setDataVerification] = useState([]);
    const [projectMedia, setProjectMedia] = useState([]);
    const [projectLinks, setProjectLinks] = useState([]);
    const [demoViewer, setDemoViewer] = useState(false);
    const [imageView, setImaveView] = useState('');
    const [bigCover, setBigCover] = useState('');



    function resetData(currentName, cover) {

        if (currentName === projectName) {
            return;
        }

        setProjectName(currentName);
        setProjectData();
        setProjectEmbed();
        setProjectMedia();
        setProjectLinks();
        setBigCover(`https://${cover}`);
    }

    useEffect(() => {
        const getProjects = async () => {
            try {
                const { data: projectsData } = await axios.get(`https://porfolio-b6670-default-rtdb.firebaseio.com/proyectos.json${process.env.REACT_APP_API_KEY}`);
                const { data: projectsMedia } = await axios.get(`https://porfolio-b6670-default-rtdb.firebaseio.com/media_proyecto.json${process.env.REACT_APP_API_KEY}`);
                const { data: CofcofProjects } = await axios.get(`https://porfolio-b6670-default-rtdb.firebaseio.com/pagina_proyecto.json${process.env.REACT_APP_API_KEY}`);
                const { data: covers } = await axios.get(`https://porfolio-b6670-default-rtdb.firebaseio.com/portada_proyecto.json${process.env.REACT_APP_API_KEY}`);
                const { data: order } = await axios.get(`https://porfolio-b6670-default-rtdb.firebaseio.com/orden_proyecto.json${process.env.REACT_APP_API_KEY}`);

                let firstName = getFirstProjectName(sortProjects(order, getCheckedProjects(CofcofProjects)));

                setProjectName(firstName);
                setCheckedProjects(sortProjects(order, getCheckedProjects(CofcofProjects)));
                setProjectsCovers(getProjectsCovers(covers, order));
                setProjectData(getProjectData(firstName, projectsData));
                setProjectEmbed(getProjectEmbed(getProjectData(firstName, projectsData)));
                setProjectMedia(getProjectMedia(firstName, projectsMedia));
                setProjectLinks(getProjectLinks(getProjectData(firstName, projectsData)));
                setDataVerification(getDataVerification(getProjectData(firstName, projectsData)));
                setBigCover(`https://${getFirstProjectCover(getProjectsCovers(covers), sortProjects(order, getCheckedProjects(CofcofProjects)))}`);
            }

            catch (error) {
                console.log('Error! D:', error);
            }
        }

        getProjects();
    }, [])

    useEffect(() => {

        const getProjects = async () => {
            try {
                const { data: projectsData } = await axios.get(`https://porfolio-b6670-default-rtdb.firebaseio.com/proyectos.json${process.env.REACT_APP_API_KEY}`);
                const { data: projectsMedia } = await axios.get(`https://porfolio-b6670-default-rtdb.firebaseio.com/media_proyecto.json${process.env.REACT_APP_API_KEY}`);
                const { data: CofcofProjects } = await axios.get(`https://porfolio-b6670-default-rtdb.firebaseio.com/pagina_proyecto.json${process.env.REACT_APP_API_KEY}`);
                const { data: covers } = await axios.get(`https://porfolio-b6670-default-rtdb.firebaseio.com/portada_proyecto.json${process.env.REACT_APP_API_KEY}`);
                const { data: order } = await axios.get(`https://porfolio-b6670-default-rtdb.firebaseio.com/orden_proyecto.json${process.env.REACT_APP_API_KEY}`);

                setCheckedProjects(sortProjects(order, getCheckedProjects(CofcofProjects)));
                setProjectsCovers(getProjectsCovers(covers));
                setProjectData(getProjectData(projectName, projectsData));
                setProjectEmbed(getProjectEmbed(getProjectData(projectName, projectsData)));
                setProjectMedia(getProjectMedia(projectName, projectsMedia));
                setProjectLinks(getProjectLinks(getProjectData(projectName, projectsData)));
                setDataVerification(getDataVerification(getProjectData(projectName, projectsData)));
            }

            catch (error) {
                console.log('Error! D:', error);
            }
        }

        getProjects();

    }, [projectName])


    return (
        <section className='works_section' id='works'>

            <div className='poster_container'>
                {projectData && bigCover ? <img src={bigCover} className='xd' alt='cover' /> : <img src='./assets/loading-gif/a.gif' alt='loading_cover' />}

                <div className='projects_carousel_container'>

                    <Swiper
                        breakpoints={{
                            // when window width is >= 0
                            0: {
                                slidesPerView: 4
                            },

                            // when window width is >= 768
                            768: {
                                slidesPerView: 6,
                                spaceBetween: 18
                            },

                            // when window width is >= 1020
                            1020: {
                                slidesPerView: 4,
                                spaceBetween: 30
                            }
                        }}
                        slidesPerView="auto"
                        watchSlidesProgress={true}
                        modules={[Navigation]}
                        navigation={{
                        }}
                        className='projects_swiper'
                    >
                        {
                            checkedProjects.map(project => {
                                return projectsCovers.map(cover => {
                                    if (cover === undefined || project === undefined) {
                                        return;
                                    }

                                    else if (cover && project && cover.nombre_proyecto === Object.values(project)[0].nombre_proyecto) {
                                        return (
                                            <SwiperSlide onClick={(e) => { resetData(cover.nombre_proyecto, cover.link_media) }} className='swiper_slide' key={project.link_media}>
                                                <div>
                                                    <img src={`https://${cover.link_media}`} alt="cover" />
                                                </div>
                                            </SwiperSlide>
                                        )
                                    }
                                })
                            })
                        }
                    </Swiper>
                </div>
            </div>

            <div id='info_container' className='info_container'>
                <div className='pad'></div>

                {projectData ? <h4>{Object.values(projectData)[0].nombre_proyecto_mostrar}</h4> : null}

                <div className='init_info_container'>
                    {
                        projectData && dataVerification !== undefined && dataVerification.includes('cliente')

                            ?

                            <div className='client'>
                                <p>Client</p>
                                <p>{Object.values(projectData)[0].cliente}</p>
                            </div>

                            :

                            null
                    }

                    {
                        projectData && dataVerification !== undefined && dataVerification.includes('fechas')

                            ?

                            <div className='date'>
                                <p>Date</p>
                                <p>
                                    {Object.values(projectData)[0].fecha_inicio}
                                    <br />
                                    {Object.values(projectData)[0].fecha_fin}
                                </p>
                            </div>

                            :

                            null
                    }

                    {
                        projectData && dataVerification !== undefined && dataVerification.includes('software')

                            ?

                            <div className='softwares'>
                                <p>Softwares</p>
                                <p>{Object.values(projectData)[0].software}</p>
                            </div>

                            :

                            null
                    }
                </div>

                {
                    projectData && dataVerification !== undefined && dataVerification.includes('descripcion')

                        ?

                        <p className='project_desc'>{Object.values(projectData)[0].descripcion}</p>

                        :

                        null
                }

                <div>
                    {
                        projectEmbed && dataVerification !== undefined && dataVerification.includes('embebido')

                            ?

                            <div className='video' dangerouslySetInnerHTML={{ __html: projectEmbed }}></div>

                            :

                            null
                    }

                    {
                        projectMedia && dataVerification !== undefined && projectMedia !== undefined

                            ?

                            projectMedia.map(media => {
                                return media.cofcof === true && media.tipo === 'video' ? <video className='video' src={`https://${media.link_media}`} key={media.link_media} controls /> : null
                            })

                            :

                            null
                    }


                    <div className='project_images_carousel_container'>
                        <Swiper
                            breakpoints={{
                                // when window width is >= 0
                                0: {
                                    slidesPerView: 3,
                                    spaceBetween: 10
                                },

                                // when window width is >= 768
                                768: {
                                    slidesPerView: 4,
                                    spaceBetween: 15
                                },

                                // when window width is >= 1020
                                1020: {
                                    slidesPerView: 4,
                                    spaceBetween: 20
                                }
                            }}
                            slidesPerView="auto"
                            watchSlidesProgress={true}
                            modules={[Navigation]}
                            navigation={{
                            }}
                            className='swiper_gallery'
                        >
                            {
                                projectMedia && projectMedia !== undefined

                                    ?

                                    projectMedia.map(media => {
                                        if (media.cofcof === true && media.tipo === 'imagen') {
                                            return (
                                                <SwiperSlide className='swiper_gallery_slide' key={media.link_media}>
                                                    <div onClick={(e) => setDemoViewer(!demoViewer)}>
                                                        <img src={`https://${media.link_media}`} onClick={(e) => setImaveView(`https://${media.link_media}`)} alt='project demo' />
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        }
                                    })

                                    :

                                    null
                            }
                        </Swiper>

                        <div>
                            {
                                projectData && dataVerification !== undefined && dataVerification.includes('links')

                                    ?

                                    <div className='project_links_container'>
                                        <p className='project_links_container_title'>Links</p>

                                        {
                                            projectLinks.map(link => {
                                                return (
                                                    <div className='project_link'>
                                                        <span>{getProjectLinksName(link)}: </span><a href={getProjectLinksDirection(link)} target='_blank' rel="noreferrer">{getProjectLinksDirection(link)}</a>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                    :

                                    null
                            }
                        </div>
                    </div>
                </div>
            </div>


            <div className={demoViewer ? 'image_view_container activated' : 'image_view_container'} onClick={(e) => setDemoViewer(!demoViewer)}>
                <div>
                    <img className='image_view' src={imageView} alt='demo view' sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw" />
                </div>
            </div>
        </section>
    )
}