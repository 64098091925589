import Header from './components/Header/Header.js';
import Navbar from './components/Navbar/Navbar.js';
import About from './components/About/About.js';
import Services from './components/Services/Services.js';
import Clients from './components/Clients/Clients.js';
import Counters from './components/Counters/Counters.js';
import Works from './components/Works/Works.js';
import Contact from './components/Contact/Contact.js';
import './App.css';

function App() {
  return (
    <main>
      <Navbar />
      <Header />
      <About />
      <Services />
      <Clients />
      <Counters />
      <Works />
      <Contact />
    </main>
  )
}


export default App;