import Logo from './Header_Logo_cofcof.svg';
import Fade from 'react-reveal/Fade';
import './Header.css';

export default function Header() {
	return (
		<header className='header'>
			<Fade duration={2000}>
				<div className='image_container'>
					<img src={Logo} draggable='false' alt='Logo' />
				</div>
			</Fade>

			<Fade duration={2000} delay={1000}>
				<div className='mouse_scroll'>
					<div>
						<span className='m_scroll_arrows unu'></span>
						<span className='m_scroll_arrows doi'></span>
						<span className='m_scroll_arrows trei'></span>
					</div>
				</div>
			</Fade>
		</header>
	);
}
