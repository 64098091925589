import Polygon from './About_nav_polygon.svg';
import Fade from 'react-reveal/Fade';
import './About.scss';


export default function About() {
    return (
        <section className='about_section'>
            <div className='text_container'>
                <Fade bottom>
                    <h2>WHAT WE DO?</h2>
                    <p>Our mission is to solve problems. There is nothing we can&apos;t solve. We offer our clients a comprehensive service for the production of films and video games, from pre-production to post-production, specializing in the development of 3D pipeline for film and video games, we also provide 3D and consulting services.</p>
                </Fade>
            </div>

            <div className='nav_container'>
                <Fade bottom>
                    <img src={Polygon} alt='Polygon' />
                </Fade>

                <nav className='nav'>
                    <div className='nav_row'>
                        <Fade left delay={500}>
                            <a className='nav_link' href='#services'>SERVICES</a>
                        </Fade>
                        <Fade right delay={500}>
                            <a className='nav_link' href='#works'>PORTFOLIO</a>
                        </Fade>
                    </div>
                    <div className='nav_row nav_second_row'>
                        <Fade bottom delay={500}>
                            <a className='nav_link' href='#contact'>GET IN TOUCH</a>
                        </Fade>
                    </div>
                </nav>
            </div>
        </section >
    )
}