import React, { useState } from "react";
import axios from 'axios';
import Fade from 'react-reveal/Fade';
import './Form.css';

export default function Form() {

  const [data, setData] = useState({
    userName: '',
    userCompany: '',
    userEmail: '',
    userMessage: '',
    formError: '',
    mailSending: false,
    mailSent: false,
    error: null
  })

  let resetData = () => {
    setData({
      userName: '',
      userCompany: '',
      userEmail: '',
      userMessage: '',
      mailSending: false,
      mailSent: true
    })
  }


  let handleFormSubmit = e => {

    e.preventDefault();

    if (/\d/.test(data.userName) === true) {
      setData({ ...data, formError: "Names don't have numbers!" })

      return;
    }

    else if (/\S+@\S+\.\S+/.test(data.userEmail) === false) {
      setData({ ...data, formError: "Emails have an @ symbol and a dot!" })

      return;
    }

    else if (data.userMessage.length < 9) {
      setData({ ...data, formError: "Message has a minimum of 9 characters." })

      return;
    }

    setData({ ...data, mailSending: true });


    axios({
      method: 'post',
      url: 'https://cofcofstudios.com/mail.php',
      headers: { 'content-type': 'application/json' },
      data: data
    })
      .then(result => {
        resetData();

        setTimeout(() => {
          setData({ mailSent: false });
        }, 2000)
      })

      .catch(error => setData({ ...data, error: error.message }));
  };


  return (
    <form className='form' onSubmit={(e) => handleFormSubmit(e)}>

      <Fade left>
        <div className='flex_data'>
          <input
            id="user_name"
            name="user_name"
            className='name'
            type="text"
            placeholder="Name"
            autoComplete="off"
            required
            disabled={data.mailSending ? true : false}
            value={data.userName}
            onChange={e => setData({ ...data, userName: e.target.value })}
          ></input>

          <input
            id="user_company"
            name="user_company"
            className='company'
            type="text"
            placeholder="Company"
            autoComplete="off"
            disabled={data.mailSending ? true : false}
            value={data.userCompany}
            onChange={e => setData({ ...data, userCompany: e.target.value })}
          ></input>
        </div>

        <input
          id="user_email"
          name="user_email"
          className='mail'
          placeholder="Mail"
          autoComplete="off"
          required
          disabled={data.mailSending ? true : false}
          value={data.userEmail}
          onChange={e => setData({ ...data, userEmail: e.target.value })}
        ></input>

        <textarea
          id="message"
          name="message"
          className='message'
          placeholder="Give us all the details!"
          required
          disabled={data.mailSending ? true : false}
          value={data.userMessage}
          onChange={e => setData({ ...data, userMessage: e.target.value })}
        ></textarea>

        <div className={data.formError === '' ? 'errorAlert' : 'errorAlert show'}>{data.formError}</div>

        <button id="submit_button" type="submit">{data.mailSent ? 'Sent!' : 'Send'}</button>

      </Fade>

    </form >
  )
}
