import Fade from 'react-reveal/Fade';
import './Features.scss';


export default function Features() {
    return (
        <article id='features' className='article_features'>
            <Fade left>
                <div className='feature'>
                    <div className='feature_image'></div>
                    <h4 className='feature_title'>ALWAYS AT YOUR <br /> SIDE</h4>
                    <p className='feature_desc'>We adapt to different pipelines and this makes it easier for us to enter our partners&apos; processes and vice versa, minimizing errors, ensuring efficient production. <br /> <br /> We cover complete pre-production and production cycles.</p>
                </div>
            </Fade>

            <Fade bottom>
                <div className='feature'>
                    <div className='feature_image'></div>
                    <h4 className='feature_title'>EXPERIENCE MATTERS <br /> TOO</h4>
                    <p className='feature_desc'>Some of the biggest companies in the world have trusted us, such as Malditomaus, Amblagar, Namamba or Vascolo, among others. <br /> <br /> We have a diverse team of specialists working together to bring your vision to life.</p>
                </div>
            </Fade>

            <Fade right>
                <div className='feature'>
                    <div className='feature_image'></div>
                    <h4 className='feature_title'>FINISH YOUR PROJECT ON TIME</h4>
                    <p className='feature_desc'>We strive to deliver projects on time and, of course, ensure that client expectations are always met and exceeded. <br /> <br /> Time is <span>important</span> to us.</p>
                </div>
            </Fade>
        </article>
    )
}