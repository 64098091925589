import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';
import './Navbar.scss';


export default function Navbar() {

    const [isInViewport, setIsInViewport] = useState(false);
    const [isActivated, setIsActivated] = useState(false);


    window.onscroll = function () {
        if (window.scrollY > ((window.innerHeight * 2) - 1)) {
            setIsInViewport(true)
        } else {
            setIsInViewport(false)
        }
    }


    return (
        <Fade top when={isInViewport} duration={300}>
            <nav id='navbar' className='navbar'>
                <a href='#' className='navbar_logo'>
                    <img src='/Logo_cofcof.svg' alt='Logo' />
                </a>

                <div onClick={(e) => setIsActivated(!isActivated)} id='hamburguer' className='hamburguer_container'>
                    <div className={isActivated ? 'hamburguer activated_hamburguer' : 'hamburguer'}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>

                <div className={isActivated ? 'nav_links_container activated' : 'nav_links_container'}>
                    <a onClick={(e) => setIsActivated(!isActivated)} href='#services'>Services</a>
                    <a onClick={(e) => setIsActivated(!isActivated)} href='#works'>Portfolio</a>
                    <a onClick={(e) => setIsActivated(!isActivated)} href='#contact'>Contact</a>
                </div>
            </nav>
        </Fade>
    )
}