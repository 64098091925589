import Features from './Features/Features.js';
import SpecialServices from './SpecialServices/SpecialServices.js';
import './Services.css';


export default function Services() {
    return (
        <section id='services' className='services_section'>
            <Features />
            <SpecialServices />
        </section>
    )
}